/* *{
    font-family: sans-serif;
}
.App{
    /* background-image: linear-gradient(45deg, cyan,skyblue); 
    height: 100vh;
}


.nav{
    margin-top: auto;
    height: 10vh;
    text-align: center;
    align-items: center;
    background-color: rgba(233,233,233,0.1);
}
.nav ul{
    list-style-type: none;
    display:flex;
    justify-content: center;
    background-color: rgba(233,233,233,0.1);
}
.nav li *{
    text-decoration: none;
    margin: 3em;
    padding: 1em;
    align-items: center;
    color:brown;
    border-radius: 20%;
    margin-top: 1em;
}
.nav li *:hover{
    background-color: bisque;
}
img{
   border-radius: 50%;
   height: 30vh; 
} */

@tailwind base;
@tailwind components;
@tailwind utilities;

